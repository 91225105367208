import {v4 as uuidv4} from 'uuid';
import {I18n} from 'common/translator/i18n';

import {
  CheckoutShopifyMetadata,
  InstallmentsPrequalPageType,
  TextStyleDto,
} from '../../../types/paymentTerms';
import {defineCustomElement} from '../../../common/init';
import Bugsnag from '../../../common/bugsnag';
import {ShopPayLogo} from '../../../common/shop-pay-logo';
import {Cart} from '../../payButton/types';
import {getCart} from '../../payButton/utils';
import {getHostName} from '../../../common/utils/utils';
import {
  PAYMENT_TERMS_TEMPLATE,
  FAILED_TO_LOAD_HTML_ERROR_MESSAGE,
  SHOP_PAY_LOGO,
  TRY_PREQUAL_AMOUNT,
  PREQUAL_ALWAYS_ENABLED_AMOUNT,
} from '../constants';
import FocusLock from '../FocusLock';
import {ShopifyInstallmentsModal} from '../modal-contents';
import {ShopifyInstallmentsSamplePlansModal} from '../sample-plans-modal-contents';
import {ShopifyInstallmentsPrequalModal} from '../prequal-modal-contents';
import {
  AvailableLoanType,
  CartShopifyMetadata,
  FinancingPlan,
  Term,
  InstallmentPlan,
  ProductShopifyMetadata,
  Variant,
  MinIneligibleMessageType,
  InstallmentsBannerType,
  InstallmentsBannerContent,
  VariantModalDetails,
  BannerTemplateCodeSignature,
} from '../../../types';
import {
  formatPrice,
  inferBackgroundColor,
  cartSubtotalSelectorsForTheme,
  convertPriceToNumber,
  getProductQuantityFromDOM,
  getMinIneligibleMessageType,
  getNewProductVariantId,
  confirmCartSubtotalAttribute,
  extractFontFaceDeclarationForFontFamily,
} from '../utils';
import {
  closePaymentTermsModal,
  openPaymentTermsModal,
} from '../utils/termsModal';
import {isShopifyMetadata} from '../utils/metadataValidation';
import {PrequalAmount} from '../prequal-amount';

import {MonorailTrackerPaymentTerms} from './MonorailTrackerPaymentTerms';

interface ComponentMetadata {
  name: string;
  shopifyMeta: any;
  variantId: string | null;
}

const DEFAULT_NUMBER_OF_PAYMENT_TERMS = 4;

export class ShopPayInstallmentsBanner extends HTMLElement {
  static get observedAttributes() {
    return ['variant-id', 'c2hvcGlmeS0=meta'];
  }

  private _variants?: Variant[];
  private _currentVariantId?: number;
  private _pricePerTerm?: string;
  private _fullPrice?: string;
  private _minPrice = '$50';
  private _minPriceNumber?: number;
  private _maxPrice = '$3000';
  private _maxPriceNumber?: number;
  private _open = false;
  private _eligible = false;
  private _numberOfPaymentTerms = DEFAULT_NUMBER_OF_PAYMENT_TERMS;
  private _hasChangeEventListener = false;
  private _loanTypes: AvailableLoanType[] = [];
  private _monorailTracker: MonorailTrackerPaymentTerms;
  private _modalMonorailTracker: MonorailTrackerPaymentTerms;
  private _installmentPlans?: InstallmentPlan[];
  private _lastLearnMoreEventListener = new WeakMap<HTMLElement, () => void>();
  private _financingTermForBanner?: Term;
  private _metaType: InstallmentsBannerType = InstallmentsBannerType.Product;
  private _backgroundColor = '';
  private _minIneligibleMessageType?: MinIneligibleMessageType;
  private _cartDetails?: Cart;
  private _didMount = false;
  private _variantAvailable = true;
  private _installmentsBuyerPrequalificationEnabled?: boolean;
  private _sellerId?: string | undefined;
  private _analyticsTraceId: string;
  private _i18n: I18n | null = null;

  constructor() {
    super();
    this._analyticsTraceId = uuidv4();

    this._monorailTracker = new MonorailTrackerPaymentTerms({
      elementName: 'shop-pay-installments-banner',
      analyticsTraceId: this._analyticsTraceId,
    });
    this._modalMonorailTracker = new MonorailTrackerPaymentTerms({
      elementName: 'c2hvcGlmeS0=installments-modal',
      analyticsTraceId: this._analyticsTraceId,
    });

    if (!customElements.get('c2hvcGlmeS0=installments-modal')) {
      customElements.define(
        'c2hvcGlmeS0=installments-modal',
        ShopifyInstallmentsModal,
      );
    }

    if (!customElements.get('c2hvcGlmeS0=installments-sample-plans-modal')) {
      customElements.define(
        'c2hvcGlmeS0=installments-sample-plans-modal',
        ShopifyInstallmentsSamplePlansModal,
      );
    }

    if (!customElements.get('c2hvcGlmeS0=installments-prequal-modal')) {
      customElements.define(
        'c2hvcGlmeS0=installments-prequal-modal',
        ShopifyInstallmentsPrequalModal,
      );
    }

    if (!customElements.get('shop-pay-logo')) {
      customElements.define('shop-pay-logo', ShopPayLogo);
    }

    this.attachShadow({mode: 'open'}).innerHTML = PAYMENT_TERMS_TEMPLATE;
  }

  async initTranslations() {
    if (this._i18n) return;
    try {
      const locale = I18n.getDefaultLanguage();
      const dictionary = await import(`../translations/${locale}.json`);
      this._i18n = new I18n({[locale]: dictionary});
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  }

  attributeChangedCallback() {
    if (this._didMount) {
      this.updateBanner();
    }
  }

  async connectedCallback() {
    await this.initTranslations();
    this.updateBanner();
    this._didMount = true;
  }

  // Values are populated after handleProductMeta/handleCartMeta are completed
  isInAdaptiveRangeWithoutZeroInterest() {
    return (
      this._eligible &&
      !this._hasZeroInterestLoanType() &&
      this._loanTypes.length === 2 &&
      this._loanTypes.includes(AvailableLoanType.SplitPay) &&
      this._loanTypes.includes(AvailableLoanType.Interest)
    );
  }

  updateBanner() {
    try {
      const shopifyMeta = this.getAttribute('c2hvcGlmeS0=meta');
      if (shopifyMeta) {
        const parsedShopifyMeta = JSON.parse(shopifyMeta);
        this._backgroundColor = inferBackgroundColor(this.shadowRoot);
        const lowestLoanTypes = this._getLowestLoanTypes(
          parsedShopifyMeta.financing_plans,
        );
        this._minIneligibleMessageType =
          getMinIneligibleMessageType(lowestLoanTypes);

        if (
          isShopifyMetadata(
            parsedShopifyMeta,
            this._monorailTracker.trackInvalidInstallmentBannerMetadata.bind(
              this._monorailTracker,
            ),
          )
        ) {
          if (parsedShopifyMeta.type === InstallmentsBannerType.Cart) {
            this._monorailTracker.trackElementImpression(
              InstallmentsBannerType.Cart,
            );
            this._metaType = InstallmentsBannerType.Cart;
            this.handleCartOrCheckoutMeta(parsedShopifyMeta);
          } else if (
            parsedShopifyMeta.type === InstallmentsBannerType.Checkout
          ) {
            this._monorailTracker.trackElementImpression(
              InstallmentsBannerType.Checkout,
            );
            this._metaType = InstallmentsBannerType.Checkout;
            this.handleCartOrCheckoutMeta(parsedShopifyMeta);
          } else {
            // Some shops are breaking if we require the product type.
            // If no type is provided, assume it's legacy => product
            this._monorailTracker.trackElementImpression(
              InstallmentsBannerType.Product,
            );
            this._metaType = InstallmentsBannerType.Product;
            this.handleProductMeta(parsedShopifyMeta);
          }
        }
        this.updateLearnMoreButtonAndModal();
      }
    } catch (error: any) {
      // This error is being reported often in Bugsnag. It is produced
      // by an unkown operation system using old Chromium versions.
      // Because it is causing too many noise on bugsnag we are
      // silencing it for now.
      if (
        error instanceof TypeError &&
        error.message.match(FAILED_TO_LOAD_HTML_ERROR_MESSAGE)
      ) {
        // eslint-disable-next-line no-console
        console.error(error);
      } else {
        Bugsnag.notify(error, {
          metadata: {
            component: this._componentMetadata(),
          },
        } as any);
      }
      this._clearShadowRoot();
    }
  }

  _getSellerIdInNumber() {
    return this._sellerId ? Number.parseInt(this._sellerId, 10) : undefined;
  }

  async updateLearnMoreButtonAndModal() {
    const learnMoreBtn = this.shadowRoot?.querySelector(
      '#c2hvcGlmeS0=installments-cta',
    ) as HTMLButtonElement;

    if (!this._i18n || !learnMoreBtn) return;

    const showSamplePlanModalContent = this.#canShowSamplePlanModalContent;

    if (this.#isEligibleForPrequalification) {
      learnMoreBtn.innerHTML = this._i18n.translate('banner.prequal');
    } else if (this._eligible && showSamplePlanModalContent) {
      learnMoreBtn.innerHTML = this._i18n.translate('banner.view_sample_plans');
    } else {
      learnMoreBtn.innerHTML = this._i18n.translate('banner.learn_more');
    }

    this._monorailTracker.trackInstallmentsBannerImpression(
      this._metaType,
      this._getInstallmentBannerContentType(showSamplePlanModalContent),
      this._eligible,
      BannerTemplateCodeSignature.Standard,
      this.#isEligibleForPrequalification,
      this._fullPrice,
      this._currentVariantId,
    );

    // Generate cart details after updating banner messaging in order to make sure no UX stutter occurs
    // Only await cart details for the sample plans modal, not standard
    if (
      this._metaType === InstallmentsBannerType.Cart &&
      showSamplePlanModalContent
    ) {
      this._cartDetails = await this._generateCartDetails();
    }

    this.#assignOpenPlansModalCTAClickEvent(learnMoreBtn);

    if (this.#isEligibleForPrequalificationAmount) {
      this.#initializePrequalificationAmountFlowComponents();
    }
  }

  #assignOpenPlansModalCTAClickEvent(button: HTMLButtonElement) {
    const learnMoreEventListener = async () => {
      const modalToken = uuidv4();

      // this should always be set by either handleCartMeta or
      // handleProductMeta, but if the `c2hvcGlmeS0=meta` attribute, or the
      // payload are not valid then we won't have a price, in which case we
      // don't want to continue any further.
      if (!this._pricePerTerm) {
        return;
      }

      const productForm = this._getProductForm();
      const productQuantity =
        this._metaType === InstallmentsBannerType.Product
          ? getProductQuantityFromDOM(productForm)
          : undefined;

      if (!this._open) {
        this._open = true;
        this._modalMonorailTracker.trackElementImpression(this._metaType);
        const priceRange = {
          minPrice: this._minPrice,
          maxPrice: this._maxPrice,
        };
        const variantInfo = productQuantity
          ? this.buildVariantInfo(productQuantity)
          : undefined;

        let terms:
          | ShopifyInstallmentsModal
          | ShopifyInstallmentsSamplePlansModal
          | ShopifyInstallmentsPrequalModal;

        if (this._installmentsBuyerPrequalificationEnabled && this._eligible) {
          terms = new ShopifyInstallmentsPrequalModal(
            modalToken,
            this._modalMonorailTracker,
            this._loanTypes,
            this._eligible,
            this._installmentPlans || [],
            this._fullPrice || '',
            this._sellerId,
            variantInfo,
            this._cartDetails,
            this._analyticsTraceId,
          );
          this._modalMonorailTracker.trackInstallmentsBannerPrequalInteraction(
            this._metaType,
            this._getInstallmentBannerContentType(false),
            this._eligible,
            this._fullPrice || '',
            true,
          );
        } else if (this.#canShowSamplePlanModalContent) {
          terms = new ShopifyInstallmentsSamplePlansModal(
            modalToken,
            this._modalMonorailTracker,
            this._installmentPlans || [],
            this._fullPrice || '',
            this._metaType,
            variantInfo,
            this._cartDetails,
          );
        } else {
          terms = new ShopifyInstallmentsModal(
            this._pricePerTerm,
            this._eligible,
            priceRange,
            this._loanTypes,
            modalToken,
            this._modalMonorailTracker,
            this._fullPrice,
            this._minIneligibleMessageType,
            this._numberOfPaymentTerms,
          );
        }

        await terms.connectedCallback();
        const focusLock = new FocusLock(terms.focusLockTarget);
        terms.addEventListener('shopify_modal_close', () => {
          this._open = false;
          closePaymentTermsModal();
          // Release lock on modal for a11y purposes
          focusLock.release(button);
        });

        if (this._installmentsBuyerPrequalificationEnabled) {
          this._modalMonorailTracker.trackInstallmentsPrequalPopupPageImpression(
            this._getSellerIdInNumber(),
            InstallmentsPrequalPageType.IntroPageLoaded,
          );
        }

        openPaymentTermsModal(terms);
        this._instrumentMonorailModalOpenEvent(terms, this._metaType);
        // Lock focus on modal for a11y purposes
        focusLock.lock();
      }
    };

    // Remove current event listener from learnMore button, as appending new listeners leads to some odd behaviour
    if (this._lastLearnMoreEventListener.has(button)) {
      this._lastLearnMoreEventListener.get(button)?.();
    }
    this._lastLearnMoreEventListener.set(button, () =>
      button.removeEventListener('click', learnMoreEventListener),
    );
    button.addEventListener('click', learnMoreEventListener);
  }

  get #canShowSamplePlanModalContent() {
    const onlyHasInterestLoanType =
      this._loanTypes.length === 1 &&
      this._loanTypes[0] === AvailableLoanType.Interest;

    const showSamplePlanModalContent =
      this._installmentPlans?.length &&
      this._fullPrice &&
      (this._hasZeroInterestLoanType() ||
        onlyHasInterestLoanType ||
        this.isInAdaptiveRangeWithoutZeroInterest());

    return Boolean(showSamplePlanModalContent);
  }

  get #isEligibleForPrequalification() {
    return Boolean(
      this._eligible && this._installmentsBuyerPrequalificationEnabled,
    );
  }

  get #isEligibleForPrequalificationAmount() {
    return Boolean(
      this._fullPrice &&
        this._eligible &&
        convertPriceToNumber(this._fullPrice) >= TRY_PREQUAL_AMOUNT,
    );
  }

  get #useLearnMoreCtaWithPrequalificationAmount() {
    return Boolean(
      this._fullPrice &&
        convertPriceToNumber(this._fullPrice) < PREQUAL_ALWAYS_ENABLED_AMOUNT,
    );
  }

  #inferFontStyles() {
    const containerElement = this.shadowRoot!.querySelector(
      '.c2hvcGlmeS0=installments__prequal-row',
    );
    if (!containerElement) {
      return undefined;
    }
    const styles = window.getComputedStyle(containerElement);

    return {
      color: styles.color,
      fontSize: styles.fontSize,
      fontFamily: styles.fontFamily,
      letterSpacing: styles.letterSpacing,
      fontFace: extractFontFaceDeclarationForFontFamily(styles.fontFamily),
    } as TextStyleDto;
  }

  #initializePrequalificationAmountFlowComponents() {
    const learnMoreButtonCTA = this.shadowRoot?.querySelector(
      '#c2hvcGlmeS0=installments-cta',
    ) as HTMLButtonElement;
    const shopifyPrequalifiedCTA = this.shadowRoot?.querySelector(
      '#shopifyPrequalifiedCTA',
    ) as HTMLButtonElement;
    const prequalAmountWrapper = this.shadowRoot?.querySelector(
      '#prequalAmountRowWrapper',
    );

    let prequalAmount = this.shadowRoot?.querySelector(
      '#prequalAmount',
    ) as PrequalAmount;

    if (!prequalAmount) {
      prequalAmount = document.createElement(
        'shop-prequal-amount',
      ) as PrequalAmount;
      prequalAmount.setAttribute('id', 'prequalAmount');
      shopifyPrequalifiedCTA.parentNode?.insertBefore(
        prequalAmount,
        shopifyPrequalifiedCTA,
      );
    }

    if (!shopifyPrequalifiedCTA || !this._i18n) {
      return;
    }

    shopifyPrequalifiedCTA.innerHTML = this
      .#useLearnMoreCtaWithPrequalificationAmount
      ? this._i18n.translate('banner.learn_more')
      : this._i18n.translate('banner.prequal_contents.prequalified_see_plans');

    this.#assignOpenPlansModalCTAClickEvent(shopifyPrequalifiedCTA);

    prequalAmount.onloaded = () =>
      prequalAmountWrapper?.classList.remove('stable');
    prequalAmount.styles = () => this.#inferFontStyles();
    prequalAmount.onready = (prequlified: boolean, fontLoaded: boolean) => {
      if (!prequlified) {
        return;
      }

      if (!fontLoaded) {
        const container = this.shadowRoot?.querySelector(
          '#c2hvcGlmeS0=installments',
        );
        container?.classList.add('default-font');
      }

      learnMoreButtonCTA.tabIndex = -1;
      shopifyPrequalifiedCTA.tabIndex = 0;

      requestAnimationFrame(() => {
        prequalAmountWrapper?.classList.add('is-prequalified');
        setTimeout(() => prequalAmountWrapper?.classList.add('stable'), 300);
      });
    };
  }

  handleProductMeta(productMeta: ProductShopifyMetadata) {
    this._variants = productMeta.variants;
    this._minPrice = productMeta.min_price;
    this._minPriceNumber = convertPriceToNumber(this._minPrice);
    this._maxPrice = productMeta.max_price;
    this._maxPriceNumber = convertPriceToNumber(this._maxPrice);
    this._currentVariantId = Number(this.getAttribute('variant-id'));
    this._fullPrice = this._getVariantFullPrice(this._currentVariantId);
    this._numberOfPaymentTerms = this._getNumberOfPaymentTermsForPDPVariant(
      this._currentVariantId,
    );
    this._pricePerTerm = this.updatePDPVariant(
      this._currentVariantId,
      productMeta.financing_plans,
    );
    this._sellerId = productMeta.seller_id;
    this._installmentsBuyerPrequalificationEnabled =
      productMeta.installments_buyer_prequalification_enabled;

    this._buildInstallmentPlans(productMeta.financing_plans, this._fullPrice);

    // Update price on variant change
    const productForm = this._getProductForm();
    if (productForm) {
      const onProductFormChange = (attempts = 0) => {
        // The value of the <select> element updates after an unknown amount of time based on how much
        // work a theme's custom JS is handling. We are putting some limitations by checking every 100ms
        // for up to 500ms.
        if (attempts > 4) {
          return;
        }

        const newVariantId = getNewProductVariantId(productForm);
        // Out of options as to find the new variant ID.
        if (!newVariantId) return;

        if (this._currentVariantId === newVariantId) {
          setTimeout(() => {
            onProductFormChange(attempts + 1);
          }, 100);
        } else {
          this._numberOfPaymentTerms =
            this._getNumberOfPaymentTermsForPDPVariant(newVariantId);
          this._pricePerTerm = this.updatePDPVariant(
            newVariantId,
            productMeta.financing_plans,
          );
          this._fullPrice = this._getVariantFullPrice(newVariantId);
          this._buildInstallmentPlans(
            productMeta.financing_plans,
            this._fullPrice,
          );

          this._currentVariantId = newVariantId;
          this.updateLearnMoreButtonAndModal();
        }
      };
      // We don't add the event listener if it's been added before.
      if (!this._hasChangeEventListener) {
        this._hasChangeEventListener = true;
        productForm.addEventListener('change', () => {
          onProductFormChange();
        });
      }
    }
  }

  handleCartOrCheckoutMeta(
    cartOrCheckoutMeta: CartShopifyMetadata | CheckoutShopifyMetadata,
  ) {
    const extraCartSubtotalSelectors: string | null =
      cartSubtotalSelectorsForTheme();
    this._minPrice = cartOrCheckoutMeta.min_price;
    this._minPriceNumber = convertPriceToNumber(this._minPrice);
    this._maxPrice = cartOrCheckoutMeta.max_price;
    this._maxPriceNumber = convertPriceToNumber(this._maxPrice);
    this._fullPrice = cartOrCheckoutMeta.full_price;
    this._loanTypes = this._getAvailableLoanTypes(
      this._fullPrice,
      cartOrCheckoutMeta.financing_plans,
    );
    this._eligible = cartOrCheckoutMeta.eligible;
    this._financingTermForBanner = this.getFinancingTermForCart(
      this._fullPrice,
      cartOrCheckoutMeta.financing_plans,
    );
    this._sellerId = cartOrCheckoutMeta.seller_id;
    this._installmentsBuyerPrequalificationEnabled =
      cartOrCheckoutMeta.installments_buyer_prequalification_enabled;
    this._pricePerTerm = this._financingTermForBanner
      ? this.getCartPricePerTerm(this._fullPrice, this._financingTermForBanner)
      : cartOrCheckoutMeta.price_per_term;
    this._numberOfPaymentTerms = this._financingTermForBanner
      ? this._financingTermForBanner.installments_count
      : cartOrCheckoutMeta.number_of_payment_terms;
    this.updateBannerPrice(this._pricePerTerm);

    if (this._loanTypes.length) {
      this._buildInstallmentPlans(
        cartOrCheckoutMeta.financing_plans,
        cartOrCheckoutMeta.full_price,
      );
    }

    if (!extraCartSubtotalSelectors) {
      confirmCartSubtotalAttribute();
    }

    // Update on cart change
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.nodeType !== Node.ELEMENT_NODE) {
          return;
        }
        const mutationTarget = mutation.target as Element;
        if (
          (mutationTarget.matches('[data-cart-subtotal]') ||
            (extraCartSubtotalSelectors &&
              mutationTarget.matches(extraCartSubtotalSelectors))) &&
          mutationTarget.textContent
        ) {
          const paymentFullPrice = convertPriceToNumber(
            mutationTarget.textContent,
          );
          if (paymentFullPrice) {
            this._fullPrice = mutationTarget.textContent;
            this._eligible = this._priceEligible(paymentFullPrice);
            const termPrice = this._splitCartPrice(paymentFullPrice);
            if (termPrice) {
              const formattedPrice = formatPrice(termPrice);
              this._pricePerTerm = formattedPrice;
              this.updateBannerPrice(formattedPrice);
            }
          }
        }
      });
    });

    mutationObserver.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  buildVariantInfo(productQuantity: number): VariantModalDetails {
    return {
      idQuantityMapping: `${this._currentVariantId}:${productQuantity}`,
      available: this._variantAvailable,
    };
  }

  getCartPricePerTerm(price: string, financingTerm: Term) {
    const totalCartPrice = convertPriceToNumber(price);
    return this.calculatePricePerTerm(totalCartPrice, financingTerm);
  }

  getFinancingTermForCart(price: string, financingPlans: FinancingPlan[]) {
    const totalCartPrice = convertPriceToNumber(price);

    const financingPlanForPrice = this._getFinancingPlanForPrice(
      totalCartPrice,
      financingPlans,
    );

    if (!financingPlanForPrice) {
      return undefined;
    }

    return this._getFinancingTermForBanner(financingPlanForPrice);
  }

  getContent = (price: string) => {
    if (!this._i18n) return '';

    // Checkout only wants a link to open the modal, no "banner" so to speak
    if (this._metaType === InstallmentsBannerType.Checkout) return '';

    const shopPayLogo = SHOP_PAY_LOGO(this._backgroundColor);

    if (!this._loanTypes.length) {
      return this.getIneligibleContent();
    }

    if (this._financingTermForBanner && this._hasZeroInterestLoanType()) {
      const isZeroApr = this._financingTermForBanner.apr === 0;
      return isZeroApr
        ? this._i18n.translate('banner.zero_interest_eligible_zero_apr', {
            price,
            shopPayLogo,
          })
        : this._i18n.translate('banner.zero_interest_eligible', {
            price,
            shopPayLogo,
          });
    }

    if (
      this._financingTermForBanner &&
      this.isInAdaptiveRangeWithoutZeroInterest()
    ) {
      return this._i18n.translate('banner.pay_in_4_or_as_low_as_eligible', {
        price,
        shopPayLogo,
      });
    }

    if (this._loanTypes.includes(AvailableLoanType.SplitPay)) {
      let translationKey = 'banner.split_pay_eligible';

      if (this._numberOfPaymentTerms === 2) {
        translationKey = 'banner.split_pay_eligible_2';
      } else if (this._numberOfPaymentTerms === 1) {
        translationKey = 'banner.split_pay_eligible_30';
      }

      return this._i18n.translate(translationKey, {
        price,
        shopPayLogo,
      });
    }
    const onlyInterest = this._loanTypes.includes(AvailableLoanType.Interest);

    if (onlyInterest) {
      return this._i18n.translate('banner.dynamic_interest_only_eligible', {
        price,
        shopPayLogo,
      });
    }

    return this.getIneligibleContent();
  };

  getIneligibleContent = () => {
    if (!this._i18n) return '';

    const shopPayLogo = SHOP_PAY_LOGO(this._backgroundColor);

    let translationKey = 'banner.non_eligible_min';

    if (this._minIneligibleMessageType === MinIneligibleMessageType.Monthly) {
      translationKey = 'banner.non_eligible_monthly_payments_min';
    } else if (
      this._numberOfPaymentTerms === 2 ||
      this._numberOfPaymentTerms === 1
    ) {
      translationKey = 'banner.non_eligible_min_over_time';
    }

    const minIneligibleMessage = this._i18n.translate(translationKey, {
      minPrice: this._minPrice,
      shopPayLogo,
    });

    if (!this._fullPrice) {
      return minIneligibleMessage;
    }

    const fullPriceNumber = convertPriceToNumber(this._fullPrice);
    const maxPriceNumber = convertPriceToNumber(this._maxPrice);
    if (fullPriceNumber > maxPriceNumber) {
      return this._i18n.translate('banner.non_eligible_max', {
        maxPrice: this._maxPrice,
        shopPayLogo,
      });
    }
    return minIneligibleMessage;
  };

  updatePDPVariant = (variantId: number, financingPlans: FinancingPlan[]) => {
    const variant = this._variants?.find(
      (variant) => Number(variant.id) === variantId,
    );

    if (!variant || !variant.full_price) {
      this._eligible = false;
      this._loanTypes = [];
      this.updateBannerPrice();
      return '';
    }

    this._eligible = variant.eligible;
    this._loanTypes = this._getAvailableLoanTypes(
      variant.full_price,
      financingPlans,
    );
    this._variantAvailable = variant.available;

    const variantPrice = variant.full_price;
    const variantPriceNumber = convertPriceToNumber(variantPrice);
    const financingPlanForPrice = this._getFinancingPlanForPrice(
      variantPriceNumber,
      financingPlans,
    );

    if (!financingPlanForPrice) {
      this.updateBannerPrice(variant.price_per_term);
      return variant.price_per_term;
    }

    this._financingTermForBanner = this._getFinancingTermForBanner(
      financingPlanForPrice,
    );

    if (this._financingTermForBanner.loan_type === AvailableLoanType.SplitPay) {
      this.updateBannerPrice(variant.price_per_term);
      return variant.price_per_term;
    }

    const variantPricePerTerm = this.calculatePricePerTerm(
      variantPriceNumber,
      this._financingTermForBanner,
    );
    this.updateBannerPrice(variantPricePerTerm);

    return variantPricePerTerm;
  };

  calculatePricePerTerm = (price: number, term: Term) => {
    // Divided by 100 for percentage and 12 to get per month rate
    const interestRatePerMonth = term.apr / 1200;
    const numberOfPayments = term.installments_count;

    if (interestRatePerMonth === 0) {
      return formatPrice(price / numberOfPayments);
    }

    const numerator =
      price *
      interestRatePerMonth *
      (1 + interestRatePerMonth) ** numberOfPayments;
    const denominator = (1 + interestRatePerMonth) ** numberOfPayments - 1;

    return formatPrice(numerator / denominator);
  };

  updateBannerPrice = (termPrice?: string) => {
    let content;
    if (this._eligible && termPrice) {
      content = this.getContent(termPrice);
    } else {
      content = this.getIneligibleContent();
    }

    if (this._metaType === InstallmentsBannerType.Checkout) {
      const container = this.shadowRoot?.querySelector('#c2hvcGlmeS0=installments');
      container?.classList.add('c2hvcGlmeS0=installments__inline');
    }

    const installmentsContent = this.shadowRoot?.querySelector(
      '#c2hvcGlmeS0=installments-content',
    );
    if (installmentsContent) {
      installmentsContent.innerHTML = content;
    }
  };

  private _getVariantFullPrice(variantId: number) {
    const variant = this._variants?.find(
      (variant) => Number(variant.id) === variantId,
    );

    return variant?.full_price;
  }

  private _getNumberOfPaymentTermsForPDPVariant(variantId: number) {
    const variant = this._variants?.find(
      (variant) => Number(variant.id) === variantId,
    );

    return variant?.number_of_payment_terms || DEFAULT_NUMBER_OF_PAYMENT_TERMS;
  }

  private async _generateCartDetails() {
    const extractedHostname = getHostName(window.location.origin);

    if (extractedHostname) {
      return getCart(extractedHostname);
    }

    return undefined;
  }

  private _getLowestLoanTypes(financingPlans?: FinancingPlan[]) {
    const lowestFinacingPlan = financingPlans ? financingPlans[0] : null;

    if (!lowestFinacingPlan) {
      return [];
    }

    const loanTypes = lowestFinacingPlan.terms.map((term) => {
      if (term.loan_type === AvailableLoanType.SplitPay) {
        return AvailableLoanType.SplitPay;
      }
      // if not split_pay available loan type is interest
      if (term.apr === 0) {
        return AvailableLoanType.ZeroPercent;
      } else {
        return AvailableLoanType.Interest;
      }
    });

    return loanTypes;
  }

  private _getFinancingPlanForPrice(
    price: number,
    financingPlans: FinancingPlan[],
  ): FinancingPlan | undefined {
    return financingPlans.find((financingPlan) => {
      const minPrice = convertPriceToNumber(financingPlan.min_price);
      const maxPrice = convertPriceToNumber(financingPlan.max_price);
      return price >= minPrice && price <= maxPrice;
    });
  }

  private _getFinancingTermForBanner(financingPlan: FinancingPlan): Term {
    const longestTerm = financingPlan.terms.reduce(
      (returnedTerm, currentTerm) =>
        currentTerm.installments_count > returnedTerm.installments_count
          ? currentTerm
          : returnedTerm,
    );

    if (this._hasZeroInterestLoanType()) {
      return longestTerm;
    }

    const termWithSplitPayLoanType = financingPlan.terms.find(
      (term) => term.loan_type === AvailableLoanType.SplitPay,
    );

    // If product/cart is in the adaptive range, we still want to use the longest term in the banner
    if (
      termWithSplitPayLoanType &&
      !this.isInAdaptiveRangeWithoutZeroInterest()
    ) {
      return termWithSplitPayLoanType;
    }

    return longestTerm;
  }

  private _buildInstallmentPlans(
    financingPlans: FinancingPlan[],
    totalPrice?: string,
  ) {
    if (!totalPrice) return;

    const priceNumber = convertPriceToNumber(totalPrice);

    const financingPlanForPrice = this._getFinancingPlanForPrice(
      priceNumber,
      financingPlans,
    );

    if (!financingPlanForPrice) return;

    this._installmentPlans = this._getSampleDisplayedTerms(
      financingPlanForPrice.terms,
    ).map((term) => ({
      pricePerTerm: this.calculatePricePerTerm(priceNumber, term),
      apr: term.apr,
      numberOfPaymentTerms: term.installments_count,
      loanType: term.loan_type,
    }));
  }

  private _getSampleDisplayedTerms(terms: Term[]) {
    if (terms.length < 3) return terms;

    // Show split pay option and longest interest term
    if (this.isInAdaptiveRangeWithoutZeroInterest()) {
      return [terms[0], terms[terms.length - 1]];
    }

    const termsWithoutSplitPay = terms.filter(
      (term) => term.loan_type !== AvailableLoanType.SplitPay,
    );
    if (termsWithoutSplitPay.length < 3) return termsWithoutSplitPay;

    return [
      termsWithoutSplitPay[0],
      termsWithoutSplitPay[termsWithoutSplitPay.length - 1],
    ];
  }

  private _splitCartPrice(price: number) {
    if (isNaN(price)) {
      return undefined;
    }
    return Math.floor((price / this._numberOfPaymentTerms) * 100) / 100;
  }

  private _priceEligible(price: number) {
    return (
      this._minPriceNumber != null &&
      this._maxPriceNumber != null &&
      price >= this._minPriceNumber &&
      price <= this._maxPriceNumber
    );
  }

  private _instrumentMonorailModalOpenEvent(
    modal:
      | ShopifyInstallmentsModal
      | ShopifyInstallmentsSamplePlansModal
      | ShopifyInstallmentsPrequalModal,
    elementType: InstallmentsBannerType,
  ) {
    if (modal instanceof ShopifyInstallmentsModal) {
      this._modalMonorailTracker.trackModalOpened(
        elementType,
        modal.getModalToken(),
        modal.getModalType(),
        JSON.stringify([]),
        this._currentVariantId,
        this._fullPrice,
      );
    } else {
      this._modalMonorailTracker.trackModalOpened(
        elementType,
        modal.getModalToken(),
        modal.getModalType(),
        JSON.stringify(modal.getModalSamplePlans()),
        this._currentVariantId,
        this._fullPrice,
        modal.getPermalink(),
      );
    }
  }

  private _getInstallmentBannerContentType(
    showSamplePlanModalContent: boolean,
  ) {
    if (!showSamplePlanModalContent) {
      return InstallmentsBannerContent.PayInFour;
    }

    if (this.isInAdaptiveRangeWithoutZeroInterest()) {
      return InstallmentsBannerContent.PayInFourAsLowAs;
    }

    return InstallmentsBannerContent.AsLowAs;
  }

  private _hasZeroInterestLoanType() {
    return this._loanTypes.includes(AvailableLoanType.ZeroPercent);
  }

  private _getAvailableLoanTypes(
    fullPrice: string,
    financingPlans?: FinancingPlan[],
  ): AvailableLoanType[] {
    if (!financingPlans || financingPlans.length === 0 || !fullPrice) {
      return [];
    }

    const fullPriceNumber = convertPriceToNumber(fullPrice);
    const financingPlanForPrice = this._getFinancingPlanForPrice(
      fullPriceNumber,
      financingPlans,
    );

    if (!financingPlanForPrice) {
      return [];
    }

    const loanTypes = new Set<AvailableLoanType>();
    financingPlanForPrice.terms.forEach((term) => {
      if (term.loan_type === AvailableLoanType.SplitPay) {
        loanTypes.add(AvailableLoanType.SplitPay);
      }
      // if not split_pay available loan type is interest
      else if (term.apr === 0) {
        loanTypes.add(AvailableLoanType.ZeroPercent);
      } else {
        loanTypes.add(AvailableLoanType.Interest);
      }
    });

    return Array.from(loanTypes);
  }

  private _getProductForm() {
    return (
      this.shadowRoot?.host.parentNode as ShadowRoot | null
    )?.host?.closest('form');
  }

  private _clearShadowRoot() {
    if (this.shadowRoot) {
      this.shadowRoot.innerHTML = '';
    }
  }

  private _componentMetadata(): ComponentMetadata {
    return {
      name: 'shop-pay-installments-banner',
      shopifyMeta: this.getAttribute('c2hvcGlmeS0=meta'),
      variantId: this.getAttribute('variant-id'),
    };
  }
}

/**
 * Define the shop-pay-installments-banner custom element.
 */
export function defineElement() {
  defineCustomElement(
    'shop-pay-installments-banner',
    ShopPayInstallmentsBanner,
  );
}
