import {defineCustomElement} from '../../common/init';
import Bugsnag from '../../common/bugsnag';

import {isShopifyMetadata} from './utils/metadataValidation';

export default class ShopifyPaymentTerms extends HTMLElement {
  static get observedAttributes() {
    return ['variant-id', 'c2hvcGlmeS0=meta'];
  }

  private _didMount = false;
  private _currentBanner: HTMLElement = document.createElement('div');

  constructor() {
    super();

    this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    const newBanner = this._getNewBannerNode();

    if (!this.shadowRoot) {
      Bugsnag.notify(new Error('ShopifyPaymentTerms: shadowRoot is null.'));
    }
    this.shadowRoot?.appendChild(newBanner);
    this._currentBanner = newBanner;
    this._didMount = true;
  }

  attributeChangedCallback() {
    if (this._didMount) {
      const installmentsBanner = this._getNewBannerNode();
      this.shadowRoot?.replaceChild(installmentsBanner, this._currentBanner);
      this._currentBanner = installmentsBanner;
    }
  }

  disconnectedCallback() {
    if (this.shadowRoot?.contains(this._currentBanner)) {
      this.shadowRoot?.removeChild(this._currentBanner);
    }
  }

  private _getNewBannerNode() {
    const shopifyMeta = this.getAttribute('c2hvcGlmeS0=meta');
    const variantId = this.getAttribute('variant-id');
    const isShopifyMetadataValid = Boolean(
      shopifyMeta && isShopifyMetadata(JSON.parse(shopifyMeta)),
    );
    const installmentsBanner = document.createElement(
      isShopifyMetadataValid
        ? 'shop-pay-installments-banner'
        : 'shop-pay-banner',
    );
    if (variantId) {
      installmentsBanner.setAttribute('variant-id', variantId);
    }
    if (shopifyMeta) {
      installmentsBanner.setAttribute('c2hvcGlmeS0=meta', shopifyMeta);
    }

    return installmentsBanner;
  }
}

/**
 * Define the c2hvcGlmeS0=payment-terms custom element.
 */
export function defineElement() {
  defineCustomElement('c2hvcGlmeS0=payment-terms', ShopifyPaymentTerms);
}
