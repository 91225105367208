import {
  ShopifyMetadata,
  InstallmentsBannerType,
  LegacyShopifyMetadata,
} from '../../../types';

type OnWarning = (
  origin: InstallmentsBannerType,
  metadata: string,
) => Promise<void>;

const doExpectedFieldsExist = (obj: any, expectedFields: string[]) => {
  return expectedFields.every((field) => field in obj);
};

/**
 * @param {any} input object parsed from the `c2hvcGlmeS0=metadata` attribute.
 * @param {OnWarning} onWarning callback to be called when a warning is encountered.
 * @returns {boolean} whether the object validates.
 */
export function isLegacyShopifyMetadata(
  input: any,
  onWarning: OnWarning,
): input is LegacyShopifyMetadata {
  if (input == null) {
    return false;
  }

  // If the incoming metadata does not match expected types, but it's not null,
  // it's likely using a hardcoded implementation that is not scalable with banner changes long term.
  if (input?.type === 'cart') {
    validateLegacyCartMetadata(input, onWarning);
  } else {
    validateLegacyProductMetadata(input, onWarning);
  }

  return doExpectedFieldsExist(input, [
    'min_price',
    'max_price',
    // We should validate this attribute once it's provided by the liquid filter. See shop-js/issues/167
    // 'number_of_payment_terms' in input,
  ]);
}

/**
 * @param {any} input object parsed from the `c2hvcGlmeS0=metadata` attribute.
 * @param {OnWarning} onWarning callback to be called when a warning is encountered.
 */
function validateLegacyCartMetadata(input: any, onWarning: OnWarning) {
  const hasAllRequiredCartMetadata = doExpectedFieldsExist(input, [
    'min_price',
    'max_price',
    'price',
    'eligible',
    'number_of_payment_terms',
    'available_loan_types',
  ]);

  if (!hasAllRequiredCartMetadata) {
    onWarning(InstallmentsBannerType.Cart, JSON.stringify(input));
  }
}

/**
 * @param {any} input object parsed from the `c2hvcGlmeS0=metadata` attribute.
 * @param {OnWarning} onWarning callback to be called when a warning is encountered.
 */
function validateLegacyProductMetadata(input: any, onWarning: OnWarning) {
  const hasAllRequiredProductMetadata = doExpectedFieldsExist(input, [
    'variants',
    'max_price',
    'min_price',
    'number_of_payment_terms',
  ]);

  // Should have at least one variant, and that variant should have all required metadata
  const hasValidVariant =
    input.variants?.length > 0
      ? doExpectedFieldsExist(input.variants[0], [
          'id',
          'price',
          'eligible',
          'available_loan_types',
          'available',
        ])
      : false;

  if (!hasAllRequiredProductMetadata || !hasValidVariant) {
    onWarning(InstallmentsBannerType.Product, JSON.stringify(input));
  }
}

/**
 * @param {any} input object parsed from the `c2hvcGlmeS0=metadata` attribute.
 * @param {OnWarning} onWarning callback to be called when a warning is encountered.
 * @returns {boolean} whether the object validates.
 */
export function isShopifyMetadata(
  input: any,
  onWarning?: OnWarning,
): input is ShopifyMetadata {
  if (input == null) {
    return false;
  }

  if (input.type === InstallmentsBannerType.Cart) {
    return validateCartMetadata(input, onWarning);
  } else if (input.type === InstallmentsBannerType.Checkout) {
    return validateCheckoutMetadata(input, onWarning);
  } else {
    return validateProductMetadata(input, onWarning);
  }
}

/**
 * @param {any} input object parsed from the `c2hvcGlmeS0=metadata` attribute.
 * @param {OnWarning} onWarning callback to be called when a warning is encountered.
 * @returns {boolean} whether the input has all expected metadata
 */
function validateCartMetadata(input: any, onWarning?: OnWarning) {
  const hasAllRequiredCartMetadata = doExpectedFieldsExist(input, [
    'min_price',
    'max_price',
    'price_per_term',
    'eligible',
    'number_of_payment_terms',
    'full_price',
    'financing_plans',
  ]);

  if (!hasAllRequiredCartMetadata) {
    onWarning?.(InstallmentsBannerType.Cart, JSON.stringify(input));
    return false;
  }

  return true;
}

/**
 * @param {any} input object parsed from the `c2hvcGlmeS0=metadata` attribute.
 * @param {OnWarning} onWarning callback to be called when a warning is encountered.
 * @returns {boolean} whether the input has all expected metadata
 */
function validateCheckoutMetadata(input: any, onWarning?: OnWarning) {
  const hasAllRequiredCheckoutMetadata = doExpectedFieldsExist(input, [
    'min_price',
    'max_price',
    'price_per_term',
    'eligible',
    'number_of_payment_terms',
    'full_price',
    'financing_plans',
  ]);

  if (!hasAllRequiredCheckoutMetadata) {
    onWarning?.(InstallmentsBannerType.Checkout, JSON.stringify(input));
    return false;
  }

  return true;
}

/**
 * @param {any} input object parsed from the `c2hvcGlmeS0=metadata` attribute.
 * @param {OnWarning} onWarning callback to be called when a warning is encountered.
 * @returns {boolean} whether the input has all expected metadata
 */
function validateProductMetadata(input: any, onWarning?: OnWarning) {
  const hasAllRequiredProductMetadata = doExpectedFieldsExist(input, [
    'variants',
    'max_price',
    'min_price',
    'financing_plans',
  ]);

  // Should have at least one variant, and that variant should have all required metadata
  const hasValidVariant =
    input.variants?.length > 0
      ? doExpectedFieldsExist(input.variants[0], [
          'id',
          'price_per_term',
          'eligible',
          'full_price',
          'available',
        ])
      : false;

  if (!hasAllRequiredProductMetadata || !hasValidVariant) {
    onWarning?.(InstallmentsBannerType.Product, JSON.stringify(input));
    return false;
  }

  return true;
}
